<template>
  <div class="yx-index-con">
    <lunbo :lunboData="lunboData"></lunbo>
    <about></about>
    <home-news></home-news>
  </div>
</template>
<script>
import About from './home/about.vue'
import Lunbo from '../components/carousel'
import HomeNews from './home/homeNews.vue'
import Home from './Home.vue'

export default {
  name: 'index',
  components: {
    Lunbo,
    About,
    HomeNews,
    Home
  },
  data() {
    return {
      //首页轮播图数据
      lunboData: [
        {
          imgUrl: require("../assets/images/banner01.jpg"),
          pathUrl: "",
          id: 1
        },
        {
          imgUrl: require("../assets/images/banner02.jpg"),
          pathUrl: "",
          id: 2
        },
        {
          imgUrl: require("../assets/images/securityTrain/pc/banner01.png"),
          pathUrl: "",
          id: 3
        },
        {
          imgUrl: require("../assets/images/securityTrain/pc/banner02.png"),
          pathUrl: "",
          id: 4
        },
      ]
    }
  },

  methods: {},
}
</script>
<style lang="less" scoped>
.yz-news-con {
  width: 100%;
  height: 500px;
  background: #148EFF;
}
</style>
